.app {
  text-align: center;
  background-color: #3D414A;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.beasty-logo {
  background: url('beastyqt.jpg') left center no-repeat;
  background-size: contain;
  padding-left: 1.7em;
  height: auto;
}

.app-links {
  display: flex;
  width: 90%;
}
.app-link {
  color: #E04407;
  flex: 0 0 auto;
  margin: 0 auto;
}
.app-link.aside {
  font-size: 0.6em;
  margin: 0;
}

.app table {
  border: 0 none;
  border-collapse: separate;
  width: 90%;
  margin: 0.5em auto 0.5em;
}
.app table td {
  border: 1px solid #dadada;
  border-right-color: #aaa;
  border-bottom-color: #aaa;
  padding: 4px 8px;
  width: 20%;
  height: 10vh;
}
.app table td.selected {
  border-color: #aaa;
  border-right-color: #dadada;
  border-bottom-color: #dadada;
  color: #000;
  background: #fff;
}
.bingo-pending {
  visibility: hidden;
  font-size: 2em;
}
.bingo-active {
  visibility: visible;
  font-size: 2em;
  background: #fff;
  color: #E04407;
  width: 90%;
}
.wild {
  font-size: 2em;
}
.items {
  font-size: 0.6em;
  text-align: left;
  columns: 3;
  column-gap: 2em;
}
